import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";
import { StaticImage } from "gatsby-plugin-image";
import Confetti from "react-confetti";

// utils
import { formatNumber } from "../../utils";

// constants
import constant from "../../constants";

// api calls
import { doValidatePincode } from "../../actions/bookPackActions";

// components
import BookPackCouponModal from "./BookPackCouponModal";
import AlertPopUp from "../AlertPopUp/AlertPopUp";
import DropDown from "../DropDown/DropDown";
import PlanCards from "./PlanCards";
import ToggleButton from "./ToggleButton/ToggleButton";
import ProgramIncludes from "./ProgramIncludes";

// css
import * as styles from "./BookPack.module.css";

// Confetti
var windowWidth, windowHeight;

if (typeof window !== `undefined`) {
  windowWidth = parseInt(window.innerWidth);
  windowHeight = parseInt(window.innerHeight);

  if (windowWidth <= 768) {
    windowWidth = parseInt(window.innerWidth);
    windowHeight = "2000px";
  } else {
    windowWidth = parseInt(window.innerWidth);
    windowHeight = "1200px";
  }
}

const BookPackPilotDoctorForm = props => {
  const [hasApplied, setHasApplied] = useState(false); // Used to show coupon section
  const [successMsg, setsuccessMsg] = useState("");
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showCouponCanvasModal, setShowCouponCanvasModal] = useState(false);
  const [showAlertPopUp, setShowAlertPopUp] = useState(false); // Global Alert Popup Message for non-serviceable pincode
  const [selectedLanguage, setSelectedLanguage] = useState([]); // selected language values ie using multiple checkboxes
  const [showLanguageError, setShowLanguageError] = useState("none"); // show error for language selection if left empty
  const [loading, setLoading] = useState(false);
  const [validPinCode, setValidPinCode] = useState(false);

  const [city, setCity] = useState("");
  const [prevPinCode, setPrevPinCode] = useState("");

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    setValue,
  } = useForm({ mode: "all" });

  useEffect(() => {
    setValue("name", props.name || "");
    setValue("email", props.email || "");
    setValue("phone", props.phone || "");
    setValue("doctorName", props.doctorName || "");
    if (props.pinCode) {
      setValue("pinCode", props.pinCode, { shouldValidate: true });
    }
  }, [
    props.name,
    props.email,
    props.phone,
    props.pinCode,
    props.doctorName,
    setValue,
  ]);

  const onSubmit = async data => {
    if (loading) {
      return false;
    }
    data.city = city;
    data.pinCode = prevPinCode;
    data.language = selectedLanguage;

    if (selectedLanguage.length > 0) props.handlePayNow(data);
  };

  // Validate pin code --> Serviceable or not
  const validatePinCode = async value => {
    const watchPinCode = watch("pinCode");
    if (watchPinCode === prevPinCode) {
      return;
    }

    try {
      setLoading(true);
      setValidPinCode(false);
      const response = await doValidatePincode(value);
      const foundPincode = response.results;
      setLoading(false);

      if (foundPincode.length === 0) {
        return "Your Pin code is not serviceable";
      }

      const labValue = foundPincode[0]["labServiceability"] || "";
      const tsaValue = foundPincode[0]["tsaServiceability"] || "";
      const city = foundPincode[0].city || "";

      if (labValue === "No" && tsaValue === "No") {
        return "Your Pin code is not serviceable";
      }

      setCity(city);
      setPrevPinCode(value);
      setValidPinCode(true);
    } catch (error) {
      console.log("Something went wrong");
      return "Pin code server error";
    }
  };

  // Coupon Modal
  const handleCouponModal = () => {
    setShowCouponModal(true);
    setShowCouponCanvasModal(true);
  };

  // Handle language selection
  const handleLanguageSelection = value => {
    if (value.length > 0) {
      setShowLanguageError("none");
      setSelectedLanguage(value);
    } else {
      setShowLanguageError("block");
    }
  };

  let buttonPrice = "";
  if (props.selectedPlan === "installmentPlan") {
    buttonPrice = formatNumber(props.firstInstallmentPrice);
  } else if (props.selectedPlan === "quarterlyPlan") {
    buttonPrice = formatNumber(Math.round(props.quarterlyPlanPrice));
  } else if (props.selectedPlan === "monthlyPlan") {
    buttonPrice = formatNumber(Math.round(props.monthlyPlanPrice));
  } else {
    buttonPrice = formatNumber(props.annualPlanPrice);
  }

  return (
    <>
      <div>
        {/* Show Confetti */}
        {showConfetti === true ? (
          <Confetti width={windowWidth} height={windowHeight} />
        ) : (
          ""
        )}
        <div data-testid="bookPackForm">
          <div className={styles.bookPackFormV2__topContntSec}>
            <div className={styles.bookPackFormV2__topContntSec__hdng}>
              <h3>Select Precision Treatment</h3>
            </div>

            <div className={styles.bookPackFormV2__topContntSec__toggleSec}>
              <ToggleButton handleToggleButton={props.handleToggleButton} />
            </div>

            <ProgramIncludes />

            <div className="bgWhiteColor">
              {props.toggleButtonValue === "pilot_diabetes" && (
                <PlanCards
                  annualPlanPrice={props.annualPlanPrice}
                  installmentPlanPrice={props.installmentPlanPrice}
                  firstInstallmentPrice={props.firstInstallmentPrice}
                  handlePlan={props.handlePlan}
                  selectedPlan={props.selectedPlan}
                  quarterlyPlanPrice={props.quarterlyPlanPrice}
                  monthlyPlanPrice={props.monthlyPlanPrice}
                  showAnnualCard={props.showAnnualCard}
                  showInstallmentCard={props.showInstallmentCard}
                  showQuarterly={props.showQuarterly}
                  showMonthly={props.showMonthly}
                  annualOfferPrice={props.annualOfferPrice}
                  installmentOfferPrice={props.installmentOfferPrice}
                  quarterlyOfferPrice={props.quarterlyOfferPrice}
                  monthlyOfferPrice={props.monthlyOfferPrice}
                />
              )}

              {props.toggleButtonValue === "pilot_obesity" && (
                <PlanCards
                  annualPlanPrice={props.annualPlanPrice}
                  installmentPlanPrice={props.installmentPlanPrice}
                  firstInstallmentPrice={props.firstInstallmentPrice}
                  handlePlan={props.handlePlan}
                  selectedPlan={props.selectedPlan}
                  quarterlyPlanPrice={props.quarterlyPlanPrice}
                  monthlyPlanPrice={props.monthlyPlanPrice}
                  showAnnualCard={props.showAnnualCard}
                  showInstallmentCard={props.showInstallmentCard}
                  showQuarterly={props.showQuarterly}
                  showMonthly={props.showMonthly}
                  annualOfferPrice={props.annualOfferPrice}
                  installmentOfferPrice={props.installmentOfferPrice}
                  quarterlyOfferPrice={props.quarterlyOfferPrice}
                  monthlyOfferPrice={props.monthlyOfferPrice}
                />
              )}

              {props.toggleButtonValue === "pilot_pre_diabetes" && (
                <PlanCards
                  annualPlanPrice={props.annualPlanPrice}
                  installmentPlanPrice={props.installmentPlanPrice}
                  firstInstallmentPrice={props.firstInstallmentPrice}
                  handlePlan={props.handlePlan}
                  selectedPlan={props.selectedPlan}
                  quarterlyPlanPrice={props.quarterlyPlanPrice}
                  monthlyPlanPrice={props.monthlyPlanPrice}
                  showAnnualCard={props.showAnnualCard}
                  showInstallmentCard={props.showInstallmentCard}
                  showQuarterly={props.showQuarterly}
                  showMonthly={props.showMonthly}
                  annualOfferPrice={props.annualOfferPrice}
                  installmentOfferPrice={props.installmentOfferPrice}
                  quarterlyOfferPrice={props.quarterlyOfferPrice}
                  monthlyOfferPrice={props.monthlyOfferPrice}
                />
              )}

              {props.toggleButtonValue === "pilot_metabolic_wellness" && (
                <PlanCards
                  annualPlanPrice={props.annualPlanPrice}
                  installmentPlanPrice={props.installmentPlanPrice}
                  firstInstallmentPrice={props.firstInstallmentPrice}
                  handlePlan={props.handlePlan}
                  selectedPlan={props.selectedPlan}
                  quarterlyPlanPrice={props.quarterlyPlanPrice}
                  monthlyPlanPrice={props.monthlyPlanPrice}
                  showAnnualCard={props.showAnnualCard}
                  showInstallmentCard={props.showInstallmentCard}
                  showQuarterly={props.showQuarterly}
                  showMonthly={props.showMonthly}
                  annualOfferPrice={props.annualOfferPrice}
                  installmentOfferPrice={props.installmentOfferPrice}
                  quarterlyOfferPrice={props.quarterlyOfferPrice}
                  monthlyOfferPrice={props.monthlyOfferPrice}
                />
              )}
            </div>

            {/* FORM */}
            <div className={`bgLightBlue`}>
              <div
                className={styles.bookpackForm__formHdng}
                data-testid="bookpack_formHdng"
              >
                <p>Complete user details</p>
              </div>
              {/* Form */}
              <div className="container">
                <form
                  name="bookPack"
                  onSubmit={handleSubmit(onSubmit)}
                  data-testid="bookPackForm_form"
                  className={styles.bookPackFormV2__formSec}
                >
                  <div className="row">
                    {/* Start - Form_Input_Boxes */}
                    {/* Name */}
                    <div className={`col-md-6`}>
                      <div
                        className={`form-group ${styles.bookpackForm_formGrp}`}
                      >
                        <label className="mb-2" htmlFor="name">
                          Full Name*
                        </label>
                        <input
                          className={
                            errors.name
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          placeholder="Full name"
                          id="name"
                          type="text"
                          data-testid="bookpack_name"
                          {...register("name", {
                            required: "Please enter your name",
                            pattern: {
                              value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                              message: "Please enter your valid name",
                            },
                            maxLength: {
                              value: 50,
                              message: "Name is too long",
                            },
                          })}
                        />
                        {errors.name && (
                          <span className="invalid-feedback">
                            {errors.name.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* Phone */}
                    <div className={`col-md-6 ${styles.bookpackForm_form_col}`}>
                      <div
                        className={`form-group ${styles.bookpackForm_formGrp}`}
                      >
                        <label className="mb-2" htmlFor="phone">
                          Phone*
                        </label>
                        <input
                          className={`form-control letterSp1 ${
                            errors.phone ? "is-invalid" : ""
                          }`}
                          placeholder="Phone number"
                          id="phone"
                          type="text"
                          inputMode="numeric"
                          maxLength="10"
                          data-testid="bookpack_phone"
                          {...register("phone", {
                            required: "Please enter your mobile number",
                            pattern: {
                              value:
                                /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                              message: "Please enter your valid phone number",
                            },
                            maxLength: {
                              value: 10,
                              message: "Invalid phone number",
                            },
                          })}
                        />
                        {errors.phone && (
                          <span className="invalid-feedback">
                            {errors.phone.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* Email */}
                    <div
                      className={`col-md-6 ${styles.bookpackForm_form_col2}`}
                    >
                      <div
                        className={`form-group ${styles.bookpackForm_formGrp}`}
                      >
                        <label className="mb-2" htmlFor="email">
                          Email*
                        </label>
                        <input
                          className={
                            errors.email
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          placeholder="Email id"
                          id="email"
                          type="email"
                          data-testid="bookpack_email"
                          {...register("email", {
                            required: "Please enter your email",
                            pattern: {
                              value:
                                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                              message: "Please enter your valid email",
                            },
                          })}
                        />
                        {errors.email && (
                          <span className="invalid-feedback">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* Pin-Code */}
                    <div
                      className={`col-md-6 ${styles.bookpackForm_form_col2}`}
                    >
                      <div
                        className={`form-group ${styles.bookpackForm_formGrp}`}
                      >
                        <div>
                          <label className="mb-2" htmlFor="pinCode">
                            Pin Code*
                          </label>
                          <input
                            className={
                              errors.pinCode
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            id="pinCode"
                            type="text"
                            maxLength="6"
                            placeholder="Pin code"
                            inputMode="numeric"
                            {...register("pinCode", {
                              required: "Please enter your pin code",
                              pattern: {
                                value: /^[1-9][0-9]{5}$/,
                                message: "Please enter your valid pin code",
                              },
                              maxLength: {
                                value: 6,
                                message: "Invalid pin code",
                              },
                              validate: validatePinCode,
                            })}
                          />
                          {loading ? (
                            <span style={{ fontSize: "80%" }}>Loading...</span>
                          ) : errors.pinCode ? (
                            <span className="invalid-feedback">
                              {errors.pinCode.message}
                            </span>
                          ) : (
                            validPinCode && (
                              <span
                                className="text-success"
                                style={{ fontSize: "80%" }}
                              >
                                Your Pin code is serviceable
                              </span>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Languange */}
                    <div className="col-12 col-md-6">
                      <div
                        className={`form-group mb-3 ${styles.bookpackForm__lngFormInput}`}
                      >
                        <label htmlFor="language">Preferred Language* </label>
                        <DropDown
                          displayValue="display"
                          onSelect={handleLanguageSelection}
                          onRemove={handleLanguageSelection}
                          options={constant.BOOKPACK_LANGUAGES}
                          showCheckbox={true}
                          selectionLimit={2}
                          placeholder="Select a language (upto two)"
                          hidePlaceholder={true}
                          showArrow={true}
                          customCloseIcon={
                            <StaticImage
                              src="../../images/bookPack/cross_2.png"
                              alt="cross_icon"
                              className="img-fluid"
                              placeholder="blurred"
                              quality={90}
                              style={{
                                width: "14px",
                                height: "14px",
                                marginLeft: "4px",
                              }}
                            />
                          }
                          customArrow={
                            <StaticImage
                              src="../../images/bookPack/arrow_dropdown.png"
                              alt="cross_icon"
                              className="img-fluid"
                              placeholder="blurred"
                              quality={90}
                              style={{
                                marginTop: "9px",
                              }}
                            />
                          }
                        />
                        {/* To show language error */}
                        {selectedLanguage.length === 0 && (
                          <span
                            className="text-danger"
                            style={{
                              fontSize: "0.875em",
                              display: showLanguageError,
                            }}
                          >
                            <p style={{ marginTop: "4px" }}>
                              Please select a language
                            </p>
                          </span>
                        )}
                      </div>
                    </div>

                    {/* Referred Doctor Name */}
                    <div
                      className={`col-md-6 ${styles.bookpackForm_form_col2}`}
                    >
                      <div
                        className={`form-group ${styles.bookpackForm_formGrp}`}
                      >
                        <label className="mb-2" htmlFor="doctorName">
                          Referred Doctor Name*
                        </label>
                        <input
                          className={
                            errors.doctorName
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          placeholder="Referred Doctor Name"
                          id="doctorName"
                          type="text"
                          data-testid="bookpack_name"
                          {...register("doctorName", {
                            required: "Please enter the referred doctor name",
                            pattern: {
                              value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                              message: "Please enter valid doctor name",
                            },
                            maxLength: {
                              value: 50,
                              message: "Docotor name is too long",
                            },
                          })}
                        />
                        {errors.doctorName && (
                          <span className="invalid-feedback">
                            {errors.doctorName.message}
                          </span>
                        )}
                      </div>
                    </div>

                    {/* Coupon */}
                    {props.showCouponSection ? (
                      <div
                        className={`col-md-4 ${styles.bookpackForm_cpn_sec}`}
                      >
                        {hasApplied === true && props.coupon !== "" ? (
                          <div className={styles.bookpackForm_cpn_appliedSec}>
                            <span className="text-success">
                              {successMsg}
                              <span
                                className={`icon-cross ${styles.bookpackForm_cpn_can_icn}`}
                                aria-hidden="true"
                                role="presentation"
                                onClick={() => {
                                  setHasApplied(false);
                                  props.removedCoupon(true);
                                }}
                              ></span>
                            </span>
                          </div>
                        ) : (
                          <div
                            className={styles.bookpackForm_cpn_cta}
                            role="presentation"
                            onClick={() => handleCouponModal()}
                            data-testid="bookpack_couponTxt"
                          >
                            Apply Coupon Code
                            <span
                              className={`icon-coupon ${styles.bookpackForm_cpn_icn}`}
                            ></span>
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* End - Form_Input_Boxes */}

                    {/* Start - Terms_&_Conditions  */}
                    <div
                      className={`col-12 ${styles.bookPackFormV2__termsCol}`}
                    >
                      <div className={`form-group mb-3`}>
                        <div class="custom-control">
                          <div className="d-flex align-items-center">
                            <input
                              className={`custom-control-input ${styles.bookPackV2__checkbox}`}
                              type="checkbox"
                              id="flexCheckChecked"
                              {...register("terms", {
                                required: "Please check terms and conditions",
                              })}
                            />
                            <label
                              className={`custom-control-label ${styles.bookPackV2__checkLabel}`}
                              for="flexCheckChecked"
                              htmlFor="terms"
                            >
                              I agree to all Terms & Conditions
                            </label>
                          </div>
                          {errors.terms && (
                            <span className={styles.bookPackV2__invalid}>
                              {errors.terms.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* End - Terms_&_Conditions  */}

                    {/* Start - Buy_Now CTA Button */}
                    <div className={`col-12 mt-4`}>
                      <div className="buttonClass">
                        <span className="errorclass text-center text-danger">
                          {!isValid &&
                            isSubmitted &&
                            "Please fill all the fields correctly"}
                        </span>
                        <button
                          type="submit"
                          className={`${styles.bookpackForm_cta} ${
                            props.loading && styles.bookpackForm_cta_event
                          }`}
                          data-testid="bookPackForm_cta"
                          onClick={() => setShowLanguageError("block")}
                        >
                          <div className="bookBtn" id="book-now">
                            {hasApplied === true ? (
                              <span
                                className={`d-flex align-items-center ${
                                  props.loading &&
                                  styles.bookpackForm_cta_txt_evnt
                                }`}
                              >
                                <span
                                  data-testid="bookPackForm_cta_txt"
                                  className="bookBtn"
                                >
                                  Buy Now at
                                </span>
                                <span
                                  className={`bookBtn bookBtnAmt ${styles.bookpackForm_cta_amt}`}
                                >
                                  <span>
                                    <p>{buttonPrice}</p>
                                  </span>
                                </span>
                                {/* <span
                                  className={`icon-arrow-forward ${styles.bookpackForm_cta_arrw}`}
                                  aria-hidden="true"
                                ></span> */}
                              </span>
                            ) : (
                              <span
                                className={`d-flex align-items-center ${
                                  props.loading &&
                                  styles.bookpackForm_cta_txt_evnt
                                }`}
                              >
                                <span
                                  data-testid="bookPackForm_cta_txt"
                                  className="bookBtn"
                                >
                                  Buy Now at
                                </span>
                                <span
                                  className={`bookBtn bookBtnAmt ${styles.bookpackForm_cta_amt}`}
                                >
                                  <span>
                                    <p>{buttonPrice}</p>
                                  </span>
                                </span>
                                {/* <span
                                  className={`icon-arrow-forward ${styles.bookpackForm_cta_arrw}`}
                                  aria-hidden="true"
                                ></span> */}
                              </span>
                            )}

                            <span
                              className={`${styles.bookpackPilotForm_cta_loader} ${styles.bookpackForm_cta_loader}`}
                            >
                              <Loader
                                type="TailSpin"
                                color="#FFF"
                                height={30}
                                width={30}
                                radius={1}
                                visible={props.loading}
                              />
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>
                    {/* End - Buy_Now CTA Button */}
                  </div>
                </form>
              </div>
            </div>
            {/* End- Form */}
          </div>
        </div>

        {/* Coupon Modal */}
        <BookPackCouponModal
          show={showCouponModal}
          onHide={() => setShowCouponModal(false)}
          price={props.price}
          handleCouponUpdate={props.handleCouponUpdate}
          handleSuccessMsg={msg => {
            setHasApplied(true);
            setsuccessMsg(msg);
            setTimeout(() => setShowCouponModal(false), 2000);
            setShowConfetti(true);
            setTimeout(() => setShowConfetti(false), 7000);
          }}
          coupon={props.coupon}
          selectedPlan={props.selectedPlan}
          productId={props.productId}
          showCanvasModal={showCouponCanvasModal}
          onHideCanvasModal={() => setShowCouponCanvasModal(false)}
          windowSize={props.windowSize}
          couponType={props.couponType}
          isClassification={true}
        />

        {/* Alert Message PopUp -> Non-Serviceable pincode */}
        <AlertPopUp
          text="Thank you for registering for launch invite! we will contact you soon."
          showAlertPopUp={showAlertPopUp}
          onHideAlertPopUpModal={() => setShowAlertPopUp(false)}
        />
      </div>
    </>
  );
};

export default BookPackPilotDoctorForm;
